import React from "react";
import loadable from '@loadable/component';
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import {MailIcon, PhoneIcon, LocationMarkerIcon, UserGroupIcon, UsersIcon, HomeIcon, UserIcon} from '@heroicons/react/solid';
import {graphql, Link, useStaticQuery} from "gatsby";
import SkypeIcon from "../images/skype";
import SEO from "gatsby-plugin-wpgraphql-seo";
import ContactForm from "../components/contact-form";
import {Helmet} from "react-helmet";

const LazyGeoMap = loadable(() => import("../components/map"));

const Service = ({ children, icon: Icon, className = '' }) => (
  <div className={"flex flex-col items-center mt-4 lg:mt-0" + className}>
    <Icon className="h-12 w-12 text-fourth mb-3" />
    <div className="max-w-xs text-center">
      {children}
    </div>
  </div>
)

function IndexPage() {
  const { site, allWpPage } = useStaticQuery(graphql`
    query HomePageQuery {
      site {
        siteMetadata {
          title
          description
          email
          phone
          skype
          facebook
          location
          image
          id
          siteUrl
          street
          city
          zip
          addressCountry
        }
      }
      allWpPage(filter: { isFrontPage: { eq: true }}) {
        nodes {
          nodeType
          title
          homepage {
            claim
            cta
            biographyTitle
            biography
            titleSistemica
            sistemicaDescription
            studioTitle
            studioDescription
            service1Title
            service2Title
            service3Title
            service4Title
            service1Description
            service2Description
            service3Description
            service4Description
            service1Link {
              url
            }
            service2Link {
              url
            }
            service3Link {
              url
            }
            service4Link {
              url
            }
            serviceSectionTitle
          }
          seo {
            title
            metaDesc
            focuskw
            metaKeywords
            metaRobotsNoindex
            metaRobotsNofollow
            opengraphTitle
            opengraphDescription
            opengraphImage {
              altText
              sourceUrl
              srcSet
            }
            twitterTitle
            twitterDescription
            twitterImage {
              altText
              sourceUrl
              srcSet
            }
            canonical
            cornerstone
          }
        }
      }
    }
  `);

  const { homepage, ...homePageData } = allWpPage.nodes[0];
  const data = site.siteMetadata;
  return (
    <Layout layoutType="homepage">
      <SEO post={homePageData} />
      <Helmet>
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "${data.title}",
            "image": "${data.image}",
            "@id": "${data.id}",
            "url": "${data.siteUrl}",
            "telephone": "${data.phone}",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "${data.street}",
              "addressLocality": "${data.city}",
              "postalCode": "${data.zip}",
              "addressCountry": "${data.addressCountry}"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": ${data.location[0]},
              "longitude": ${data.location[1]}
            },
            "sameAs": "${data.facebook}"
          }`}
        </script>
      </Helmet>

      <section className="mb-2">
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-1 items-center md:p-10">
          <div className="py-5 md:py-1 flex flex-col items-center">
            <h1 className="text-3xl md:text-4xl mt-10 md:mt-0 font-bold text-center tracking-wide" style={{ lineHeight: 1.5 }} dangerouslySetInnerHTML={{ __html: homepage.claim }} />
            <a href="#contact-form" className="px-3 py-1 text-white bg-primary rounded-sm w-32 text-center mt-10">{homepage.cta}</a>
          </div>
          <div className="md:px-3 md:py-5">
            <StaticImage src="../images/hero.png" alt="Volto di persona" layout="fullWidth" placeholder="tracedSVG" />
          </div>
        </div>
      </section>
      <section className="shadow-lg">
        <div className="grid grid-cols-1 md:grid-cols-2 p-4 py-12 content-center items-center md:p-8 md:gap-4 xl:p-10">
          <StaticImage src="../images/profile.png" alt="Foto della dottoressa Arianna Barazzetti"
            height={300}
            width={300}
            layout="fixed" placeholder="blurred"
            className="rounded-full mx-auto"
          />
          <div className="max-w-xs mx-auto md:max-w-lg mt-2 md:mt-2">
            <h2 className="text-2xl font-bold">{homepage.biographyTitle}</h2>
            <div dangerouslySetInnerHTML={{ __html: homepage.biography }} />
          </div>
        </div>
      </section>


      <section className="bg-primary text-white">
        <div className="py-16 px-8">
          <h2 className="text-4xl font-bold text-center mb-8">{homepage.serviceSectionTitle}</h2>
          <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap:4">
            <Service icon={UserIcon}>
              <Link to={homepage.service4Link.url} className="text-2xl font-bold mb-2 hover:underline">{homepage.service4Title}</Link>
              <div dangerouslySetInnerHTML={{ __html: homepage.service4Description}} />
            </Service>
            <Service icon={UsersIcon}>
              <Link to={homepage.service1Link.url} className="text-2xl font-bold mb-2 hover:underline">{homepage.service1Title}</Link>
              <div dangerouslySetInnerHTML={{ __html: homepage.service1Description}} />
            </Service>
            <Service icon={HomeIcon}>
              <Link to={homepage.service2Link.url} className="text-2xl font-bold mb-2 hover:underline">{homepage.service2Title}</Link>
              <div dangerouslySetInnerHTML={{ __html: homepage.service2Description}} />
            </Service>
            <Service icon={UserGroupIcon}>
              <Link to={homepage.service3Link.url} className="text-2xl font-bold mb-2 hover:underline">{homepage.service3Title}</Link>
              <div dangerouslySetInnerHTML={{ __html: homepage.service3Description}} />
            </Service>
          </div>
        </div>
      </section>

      <ContactForm />

      <section className="shadow-lg py-10">
        <div className="p-8 grid grid-cols-1 md:grid-cols-2 content-center align-center">
          <div className="max-w-md mx-auto">
            <h2 className="text-4xl font-bold text-center mb-4">{homepage.titleSistemica}</h2>
            <div dangerouslySetInnerHTML={{ __html: homepage.sistemicaDescription }} />
          </div>
          <StaticImage src="../images/sistemica.png" alt="Rete di relazioni" height={300} layout="fixed" placeholder="tracedSVG" className="mx-auto" />
        </div>
      </section>


      <section className="bg-primary shadow">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="order-2 md:order-1">
            <LazyGeoMap />
          </div>
          <div className="p-8 text-white order-1 md:order-2">
            <h2 className="text-2xl font-bold text-center mb-4">{homepage.studioTitle}</h2>
            <div className="mb-4" dangerouslySetInnerHTML={{ __html: homepage.studioDescription }} />
            <p className="flex items-center my-1"><LocationMarkerIcon className="h-5 w-5 mr-2 text-fourth" /> {site.siteMetadata.street} {site.siteMetadata.city}</p>
            <p className="flex items-center my-1"><MailIcon className="h-5 w-5 mr-2 text-fourth" /> {site.siteMetadata.email}</p>
            <p className="flex items-center my-1"><PhoneIcon className="h-5 w-5 mr-2 text-fourth" /> {site.siteMetadata.phone}</p>
            <p className="flex items-center my-1"><SkypeIcon className="h-5 w-5 mr-2 text-fourth" /> {site.siteMetadata.skype}</p>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;
