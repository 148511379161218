import React from "react";

export default function SkypeIcon(props) {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px"
             y="0px"
             fill="currentColor"
             stroke="none"
             viewBox="0 0 24.08 24.08" {...props}>
            <g>
                <path d="M23.029,13.494c0.34,0.481,0.599,1.003,0.78,1.575c0.18,0.57,0.271,1.16,0.271,1.771c0,0.875-0.177,1.697-0.527,2.467
            c-0.351,0.768-0.834,1.445-1.45,2.033c-0.616,0.582-1.333,1.039-2.157,1.371s-1.691,0.495-2.613,0.495
            c-1.313,0-2.491-0.31-3.536-0.929c-0.29,0.04-0.582,0.069-0.877,0.091c-0.296,0.02-0.589,0.028-0.882,0.028
            c-1.534,0-2.974-0.275-4.321-0.823c-1.344-0.551-2.521-1.299-3.535-2.252c-1.011-0.949-1.808-2.068-2.382-3.349
            c-0.579-1.281-0.868-2.65-0.868-4.105c0-0.282,0.014-0.55,0.044-0.798c0.027-0.248,0.064-0.501,0.102-0.754
            C0.358,9.316,0,8.243,0,7.092c0-0.856,0.177-1.665,0.528-2.426c0.35-0.76,0.83-1.421,1.446-1.982C2.59,2.122,3.311,1.68,4.13,1.356
            c0.821-0.324,1.692-0.482,2.614-0.482c0.664,0,1.287,0.078,1.879,0.237c0.592,0.161,1.146,0.385,1.657,0.678
            c0.289-0.041,0.585-0.07,0.877-0.093c0.296-0.018,0.592-0.029,0.881-0.029c1.539,0,2.984,0.268,4.335,0.801
            c1.348,0.529,2.528,1.262,3.542,2.192c1.014,0.934,1.813,2.015,2.393,3.245c0.578,1.232,0.867,2.554,0.867,3.962
            C23.176,12.386,23.128,12.929,23.029,13.494z M17.333,16.696c0.474-0.624,0.708-1.357,0.708-2.199c0-0.663-0.132-1.22-0.392-1.672
            c-0.271-0.45-0.653-0.827-1.146-1.128c-0.453-0.292-1.042-0.551-1.763-0.783c-0.725-0.182-1.494-0.36-2.307-0.542l-0.891-0.203
            c-0.239-0.051-0.429-0.094-0.572-0.133c-0.159-0.039-0.306-0.082-0.446-0.133c-0.132-0.047-0.272-0.102-0.412-0.162
            C9.84,9.627,9.612,9.472,9.431,9.29c-0.14-0.157-0.21-0.368-0.21-0.63c0-0.383,0.228-0.725,0.681-1.028
            c0.513-0.283,1.14-0.419,1.885-0.41c0.861,0,1.466,0.127,1.807,0.382c0.412,0.302,0.735,0.674,0.966,1.113
            c0.201,0.322,0.405,0.562,0.616,0.72c0.201,0.154,0.48,0.237,0.831,0.248c0.395,0,0.722-0.131,0.997-0.395
            c0.269-0.246,0.398-0.523,0.391-0.835c0-0.361-0.102-0.716-0.302-1.058c-0.198-0.321-0.531-0.663-0.99-1.026
            c-0.232-0.156-0.487-0.299-0.762-0.43c-0.276-0.132-0.586-0.248-0.923-0.346c-0.353-0.096-0.724-0.17-1.115-0.22
            c-0.392-0.049-0.82-0.072-1.294-0.072c-1.194,0-2.201,0.146-3.021,0.438c-0.881,0.304-1.548,0.722-2,1.264
            C6.536,7.521,6.31,8.141,6.31,8.865c0,0.762,0.219,1.382,0.651,1.86c0.435,0.517,1.024,0.908,1.769,1.173
            c0.708,0.283,1.613,0.531,2.723,0.745c0.415,0.098,0.778,0.181,1.099,0.244c0.316,0.062,0.603,0.133,0.854,0.21
            c0.473,0.129,0.857,0.345,1.15,0.652c0.313,0.266,0.47,0.608,0.47,1.03c0,0.583-0.286,1.055-0.851,1.415
            c-0.534,0.369-1.256,0.551-2.165,0.538c-0.728,0-1.255-0.084-1.589-0.254c-0.415-0.189-0.721-0.429-0.922-0.715
            c-0.255-0.326-0.459-0.688-0.623-1.079c-0.143-0.371-0.336-0.651-0.588-0.84c-0.235-0.191-0.51-0.286-0.833-0.286
            c-0.412,0-0.752,0.115-1.021,0.341c-0.269,0.257-0.398,0.558-0.392,0.896c0,0.589,0.218,1.178,0.65,1.768
            c0.429,0.607,0.98,1.081,1.651,1.422c0.983,0.508,2.218,0.765,3.702,0.775c1.211,0,2.29-0.18,3.229-0.539
            C16.196,17.812,16.884,17.305,17.333,16.696z"/>
            </g>
        </svg>
    );
}